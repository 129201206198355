// User model 
export default class User {
    constructor(id, firstname, lastname, username, name, email, phone, id_number, password, confirm_password, company_name, user_roles, organization_id, otp,) {
        this.id = id
        this.firstname = firstname
        this.lastname = lastname
        this.username = username
        this.name = name
        this.email = email
        this.phone = phone
        this.id_number = id_number
        this.password = password
        this.confirm_password = confirm_password
        this.company_name = company_name
        this.organization_id = organization_id
        this.user_roles = user_roles
        this.otp = otp
    }
}