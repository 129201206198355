<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }" class="mx-2">
          <a
            v-bind="attrs"
            v-on="on"
            class="ml-auto text-caption text-sm text-decoration-none"
            >Don't have an account ?</a
          >
        </template>
        <v-card>
          <v-toolbar dark color="grey lighten-3" outlined tile flat dense>
            <v-toolbar-title class="info--text">{{modal_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn v-if="plan_selected" icon dark @click="backToPlan" color="info">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon dark @click="exitModal" color="info">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row class="justify-center mt-5">
            <v-img lazy-src="img/logo-128.png" max-height="178" max-width="178" src="img/logo-128.png"></v-img>
          </v-row>
          <div v-if="select_plan" class="mt-5">
            <v-row class="justify-center">
              <v-btn-toggle v-model="toggle_exclusive" class="my-1">
                <v-btn @click="setMonthly"> MONTHLY </v-btn>
                <v-btn @click="setQuarterly"> QUARTERLY </v-btn>
                <v-btn @click="setBiannually"> BIANNUALLY </v-btn>
                <v-btn @click="setAnnually"> ANNUALLY </v-btn>
              </v-btn-toggle>
            </v-row>
            <v-row class="d-flex justify-center mt-5 mb-1">
              <p class="text-center">Choose a plan that's right for you!</p>
            </v-row>
            <v-row class="d-flex justify-center mt-5">
              <!-- Start price tab 1  -->
              <v-card :loading="loading" elevation="0" class="mt-1 mx-2" max-width="324" min-width="320" outlined>
                <template slot="progress">
                  <v-progress-linear color="info" indeterminate></v-progress-linear>
                </template>
                <v-btn elevation="0" fab large dark color="grey lighten-2" class="d-flex mx-auto mt-n8">
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                <v-card-title class="justify-center">INDIVIDUAL</v-card-title>
                <v-divider class="mx-2 mb-13"></v-divider>
                <v-card-text class="mb-n2">
                  <p class="text-center">{{ planData.basicUsers }}</p>
                  <p class="text-center">{{ planData.basicObjects }}</p>
                  <p class="text-center">{{ planData.basicQueries }}</p>
                  <p class="text-center">{{ planData.basicAnalytics }}</p>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-title class="justify-center align-end">
                  <h3>FREE</h3>
                  <!-- <span class="text-caption grey--text">per month</span> -->
                </v-card-title>
                <v-card-actions class="text-center">
                  <!-- <div class="text-center"> -->
                  <v-btn @click="gotToRegisterBasic" color="info" class="mx-auto" depressed block>
                    GET STARTED
                  </v-btn>
                  <!-- </div> -->
                </v-card-actions>
              </v-card>
              <!--start price tab 2 -->
              
              <!-- Start price tab 3  -->
              <v-card :loading="loading" elevation="0" class="mt-1 mx-2" max-width="324" min-width="320" outlined>
                <template slot="progress">
                  <v-progress-linear color="info" indeterminate></v-progress-linear>
                </template>
                <v-btn elevation="0" fab large dark color="grey lighten-2" class="d-flex mx-auto mt-n8">
                  <v-icon>mdi-account-supervisor</v-icon>
                </v-btn>
                <v-card-title class="justify-center">CORPORATE</v-card-title>

                <v-divider class="mx-0"></v-divider>
                <v-expansion-panels class="mb-1" flat outlined>
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      Select package
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item class="my-0">
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content class="ml-n4">
                                <v-list-item-title>Business</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content class="ml-n4">
                                <v-list-item-title>Professional</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content class="ml-n4">
                                <v-list-item-title>Enterprise</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content class="ml-n4">
                                <v-list-item-title>Residential Estate</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-divider class="mx-0"></v-divider>

                <v-card-text class="mb-n2">
                  <p class="text-center">{{ planData.orgUsers }}</p>
                  <p class="text-center">{{ planData.orgObjects }}</p>
                  <p class="text-center">{{ planData.orgQueries }}</p>
                  <p class="text-center">{{ planData.orgAnalytics }}</p>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-title class="justify-center">
                  <h3>
                    Ksh {{ planData.orgPrice }}
                    <span class="text-caption grey--text">per month</span>
                  </h3>
                  <!-- <span class="text-caption grey--text">per month</span> -->
                </v-card-title>
                <!-- <div> -->
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="gotToRegisterFacilityManager"
                    color="info"
                    class="mx-auto"
                    depressed
                    block
                  >
                    GET STARTED
                  </v-btn>
                  <!-- </div> -->
                </v-card-actions>
              </v-card>
              <!-- Start price tab 3  -->
            </v-row>
          </div>
          <!-- Start basic registration  -->
          <div v-if="show_basic_registration">
            <v-row>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="mt-5 mx-2">
                <v-card class="px-3 mx-auto" outlined max-width="400">
                  <!-- Default Login  -->
                  <v-btn
                    elevation="0"
                    fab
                    dark
                    color="grey lighten-2"
                    class="d-flex mx-auto my-2"
                  >
                    <v-icon color="primary">mdi-account</v-icon>
                  </v-btn>
                  <v-row class="justify-center mt-1">
                    <div class="mt-1">
                      <h4 class="grey--text text--darken-2">
                        {{ sub_heading }}
                      </h4>
                    </div>
                  </v-row>
                  <v-divider class="mt-3"></v-divider>
                  <div>
                    <v-card-text>

                      <v-form v-if="!verify_otp"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >   
                        <v-row>
                          <v-col>

                            <v-text-field
                              dense
                              v-model="user.firstname"
                              color="info"
                              append-icon="mdi-account-outline"
                              :rules="fullnameRules"
                              label="First name *"
                              outlined
                              placeholder="Your first name *"
                              class="full-width my-1"
                            ></v-text-field>

                          </v-col>
                          <v-col>

                            <v-text-field
                              dense
                              v-model="user.lastname"
                              color="info"
                              append-icon="mdi-account-outline"
                              :rules="fullnameRules"
                              label="Last name *"
                              outlined
                              placeholder="Your last name *"
                              class="full-width my-1"
                            ></v-text-field>
                            
                          </v-col>
                        </v-row>

                        <v-text-field
                          dense
                          v-model="user.email"
                          color="info"
                          append-icon="mdi-email-outline"
                          :rules="emailRules"
                          label="Your email *"
                          outlined
                          placeholder="Your email*"
                          class="full-width my-1"
                          :error-messages="apiErrors.email"
                        ></v-text-field>

                        <div class="mb-6">
                          <template>
                            <vue-phone-number-input
                              v-model="editedItem.phone"
                              required
                              :disabled="step_one_disabled"
                              @update="updatePhoneNumber"
                              :default-country-code="editedItem.national_number"
                              size="lg"
                              :error="phone_has_error"
                            ></vue-phone-number-input>
                            <span class="text-caption pl-4 red--text">{{ apiErrors.phone ? apiErrors.phone[0] : '' }}</span>
                          </template>
                        </div>

                        <v-text-field
                          dense
                          v-model="user.password"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="passwordRules"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          label="Password"
                          hint="At least 8 characters"
                          outlined
                          placeholder="Password"
                          class="full-width my-1"
                        ></v-text-field>

                        <v-text-field
                          dense
                          v-model="user.password_confirmation"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="confirmOrgPasswordRules"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          label="Confirm password"
                          hint="At least 8 characters"
                          outlined
                          placeholder="Confirm password"
                          class="full-width my-1"
                        ></v-text-field>
                      </v-form>

                      <v-form v-if="verify_otp"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >                      
                        <v-text-field
                          dense
                          v-model="registration_otp"
                          color="info"
                          append-icon="mdi-numeric"
                          :rules="otpRules"
                          label="Registration OTP"
                          outlined
                          placeholder="123456"
                          class="full-width my-1 centered-input"
                        ></v-text-field>

                        <v-row class="pb-5">
                          <v-col>
                            <v-btn 
                              :disabled="resendAfterMinute" 
                              class="no-uppercase green--text"
                              @click="handleResendOTP"
                            >Resend OTP</v-btn>
                          </v-col>
                        </v-row>

                      </v-form>


                    </v-card-text>
                    <v-card-actions class="mt-n6">
                      <v-row>
                        <v-col cols="6" md="6" sm="6">
                          <v-btn text @click="backToPlan" class="no-uppercase">
                            Back
                          </v-btn>
                        </v-col>
                        <v-col cols="6" md="6" sm="6">
                          <v-btn v-if="!verify_otp"
                            :loading="loading"
                            color="info"
                            elevation="0"
                            medium
                            block
                            class="ml-auto white--text my-1 no-uppercase"
                            @click="handleRegister"
                            >Register</v-btn
                          >
                          <v-btn v-if="verify_otp"
                            :loading="loading"
                            color="info"
                            elevation="0"
                            medium
                            block
                            class="ml-auto white--text my-1 no-uppercase"
                            @click="handleVerifyOTP('basic')"
                          >Verify OTP</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </div>
                  <v-divider></v-divider>
                  <div class="d-flex justify-center my-3">
                    <span class="text-caption grey--text"
                      >{{ this.currentYear }} &copy; Bluezone.</span
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
            </v-row>
          </div>

          <!-- Start facility registration  -->
          <div v-if="show_organisation_registration">
            <v-row>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="mt-1 mx-2">
                <!-- Right band  -->
                <v-stepper
                  style="box-shadow: none"
                  v-model="e1"
                  class="elevation-1 mx-auto"
                  alt-labels
                  outlined
                  max-width="400"
                >
                  <v-btn
                    elevation="0"
                    fab
                    dark
                    color="grey lighten-2"
                    class="d-flex mx-auto my-2"
                  >
                    <v-icon color="primary">mdi-domain</v-icon>
                  </v-btn>
                  <v-row class="justify-center mt-1">
                    <div class="mt-1">
                      <h4 class="grey--text text--darken-2">
                        {{ sub_heading }}
                      </h4>
                    </div>
                  </v-row>
                  <v-stepper-header outlined style="box-shadow: none">
                    <v-stepper-step :complete="e1 > 1" step="1">
                      Get started
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">
                      Organisation
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-divider class="my-n2"></v-divider>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-form v-if="!verify_otp"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >

                        <v-row>
                          <v-col>

                            <v-text-field
                              :disabled="step_one_disabled"
                              dense
                              v-model="org.firstname"
                              color="info"
                              append-icon="mdi-account-outline"
                              :rules="fullnameRules"
                              label="First name *"
                              outlined
                              placeholder="Your first name *"
                              class="full-width my-1"
                            ></v-text-field>

                          </v-col>
                          <v-col>

                            <v-text-field
                              :disabled="step_one_disabled"
                              dense
                              v-model="org.lastname"
                              color="info"
                              append-icon="mdi-account-outline"
                              :rules="fullnameRules"
                              label="Last name *"
                              outlined
                              placeholder="Your last name *"
                              class="full-width my-1"
                            ></v-text-field>
                            
                          </v-col>
                        </v-row>

                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="org.email"
                          color="info"
                          append-icon="mdi-email-outline"
                          :rules="emailRules"
                          label="Your email *"
                          outlined
                          placeholder="Your email*"
                          class="full-width my-1"
                          :error-messages="apiErrors.email"
                        ></v-text-field>
                        <div class="mb-6">
                          <template>
                            <vue-phone-number-input
                              v-model="editedItem.phone"
                              required
                              :disabled="step_one_disabled"
                              @update="updatePhoneNumber"
                              :default-country-code="editedItem.national_number"
                              size="lg"
                              :error="phone_has_error"
                            ></vue-phone-number-input>
                            <span class="text-caption pl-4 red--text">{{  apiErrors.phone ? apiErrors.phone[0] : ''  }}</span>
                          </template>
                        </div>
                          <!-- Password and confirm password -->
                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="org.password"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="passwordRules"
                          :type="'Password'"
                          name="input-10-1"
                          label="Password"
                          hint="At least 8 characters"
                          outlined
                          placeholder="Password"
                          class="full-width my-1"
                        ></v-text-field>
                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="org.password_confirmation"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="passwordRules"
                          :type="'Password'"
                          name="input-10-1"
                          label="Confirm password"
                          hint="At least 8 characters"
                          outlined
                          placeholder="Confirm password"
                          class="full-width my-1"
                        ></v-text-field>
                      </v-form>

                      <v-form v-if="verify_otp"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >                      
                        <v-text-field
                          dense
                          v-model="registration_otp"
                          color="info"
                          append-icon="mdi-numeric"
                          :rules="otpRules"
                          label="Registration OTP"
                          outlined
                          placeholder="123456"
                          class="full-width my-1 centered-input"
                        ></v-text-field>

                        <v-row class="pb-5">
                          <v-col>
                            <v-btn 
                              :disabled="resendAfterMinute" 
                              class="no-uppercase green--text"
                              @click="handleResendOTP"
                            >Resend OTP</v-btn>
                          </v-col>
                        </v-row>

                      </v-form>

                      <v-btn text @click="backToPlan" class="no-uppercase">
                        Back
                      </v-btn>
                      <v-btn 
                        v-if="!verify_otp && step_one_disabled"
                        :loading="loading"
                        color="info"
                        @click="e1 = 2"
                        class="float-right no-uppercase"
                      >
                        Continue
                      </v-btn>

                      <v-btn v-if="verify_otp"
                        :loading="loading"
                        color="info"
                        elevation="0"
                        medium
                        block
                        class="ml-auto white--text my-1 no-uppercase"
                        @click="handleVerifyOTP('organization')"
                      >Verify OTP</v-btn>

                      <v-btn
                        v-if="!step_one_disabled && !verify_otp"
                        :loading="loading"
                        color="info"
                        @click="handleRegister2"
                        class="float-right no-uppercase"
                      >
                        Continue
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-form
                        ref="form_org"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >
                        <v-text-field
                          dense
                          v-model="org.legal_name"
                          color="info"
                          append-icon="mdi-domain"
                          :rules="nameRules"
                          label="Legal Name"
                          outlined
                          placeholder="Organisation name *"
                          class="full-width my-1"
                        ></v-text-field>
                      </v-form>
                      <v-btn text @click="e1 = 1" class="no-uppercase">
                        Back
                      </v-btn>
                      <v-btn
                        :loading="loading"
                        color="info"
                        @click="handleRegisterOrg"
                        class="float-right no-uppercase"
                      >
                        Register Organisation
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                  <v-divider></v-divider>
                  <div class="d-flex justify-center my-3">
                    <span class="text-caption grey--text"
                      >{{ this.currentYear }} &copy; Bluezone.</span
                    >
                  </div>
                </v-stepper>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
            </v-row>
          </div>

          <!-- Start facility registration  -->
          <div v-if="show_facility_registration">
            <v-row>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="mt-1 mx-2">
                <!-- Right band  -->
                <v-stepper style="box-shadow: none" v-model="e1" class="elevation-1" alt-labels>
                    <v-stepper-header outlined style="box-shadow: none">
                        <v-stepper-step :complete="e1 > 1" step="1">
                            Get started
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2">
                            Organisation
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 3" step="3">
                      Facility
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-divider class="my-n2"></v-divider>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-form v-if="!verify_otp" ref="form" v-model="valid" lazy-validation class="mt-5">
                            <v-row>
                                <!-- First Name -->
                                <v-col>
                                    <v-text-field
                                            :disabled="step_one_disabled"
                                            dense
                                            v-model="user.firstname"
                                            color="info"
                                            append-icon="mdi-account-outline"
                                            :rules="fullnameRules"
                              label="Your first name *"
                              outlined
                              placeholder="First name *"
                              class="full-width my-1"
                            ></v-text-field>
                          </v-col>
                                <!-- Last Name -->
                          <v-col>
                              <v-text-field
                                :disabled="step_one_disabled"
                                dense
                                v-model="user.lastname"
                                color="info"
                                append-icon="mdi-account-outline"
                                :rules="fullnameRules"
                                label="Your last name *"
                                outlined
                                placeholder="Last name *"
                                class="full-width my-1"
                              ></v-text-field>
                            
                          </v-col>
                        </v-row>

                            <!-- Email -->
                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="user.email"
                          color="info"
                          append-icon="mdi-email-outline"
                          :rules="emailRules"
                          label="Your email *"
                          outlined
                          placeholder="Your email*"
                          class="full-width my-1"
                          :error-messages="apiErrors.email"
                        ></v-text-field>

                            <!-- Phone Number -->
                        <div class="mb-6">
                            <template>
                                <vue-phone-number-input
                                        v-model="editedItem.phone"
                                        required
                                        :disabled="step_one_disabled"
                                        @update="updatePhoneNumber"
                                        :default-country-code="editedItem.national_number"
                                        size="lg"
                                        :error="phone_has_error"
                                ></vue-phone-number-input>
                                <span class="text-caption pl-4 red--text">
                                {{ apiErrors.phone ? apiErrors.phone[0] : '' }}
                            </span>
                            </template>
                        </div>

                            <!-- Password -->
                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="user.password"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="passwordRules"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          label="Password"
                          hint="At least 8 characters"
                          outlined
                          placeholder="Password"
                          class="full-width my-1"
                          required
                        ></v-text-field>
                            <!-- Confirm Password -->
                        <v-text-field
                          :disabled="step_one_disabled"
                          dense
                          v-model="user.password_confirmation"
                          color="info"
                          append-icon="mdi-eye-outline"
                          :rules="confirmPasswordRules"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          label="Confirm password"
                          outlined
                          placeholder="Confirm password"
                          class="full-width my-1"
                          required
                        ></v-text-field>
                      </v-form>

                        <v-form v-if="verify_otp" ref="form" v-model="valid" lazy-validation class="mt-5">
                            <v-text-field
                                    dense
                                    v-model="registration_otp"
                                    color="info"
                                    append-icon="mdi-numeric"
                                    :rules="otpRules"
                                    label="Registration OTP"
                                    outlined
                                    placeholder="123456"
                                    class="full-width my-1 centered-input"
                        ></v-text-field>

                        <v-row class="pb-5">
                            <v-col>
                                <v-btn :disabled="resendAfterMinute" class="no-uppercase green--text"
                                       @click="handleResendOTP">
                                    Resend OTP
                                </v-btn>
                            </v-col>
                        </v-row>

                      </v-form>

                        <v-btn text @click="backToPlan" class="no-uppercase">Back</v-btn>
                      <v-btn
                        v-if="step_one_disabled && !verify_otp"
                        :loading="loading"
                        color="info"
                        @click="e1 = 3"
                        class="float-right no-uppercase"
                      >
                        Continue
                      </v-btn>

                      <v-btn v-if="verify_otp"
                        :loading="loading"
                        color="info"
                        elevation="0"
                        medium
                        block
                        class="ml-auto white--text my-1 no-uppercase"
                        @click="handleVerifyOTP('facility')"
                      >Verify OTP</v-btn>

                      <v-btn
                        v-if="!step_one_disabled && !verify_otp"
                        :loading="loading"
                        color="info"
                        @click="handleRegister3"
                        class="float-right no-uppercase"
                      >
                        Continue
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-form
                        ref="form_org"
                        v-model="valid"
                        lazy-validation
                        class="mt-5"
                      >
                        <v-text-field
                          :disabled="step_two_disabled"
                          dense
                          v-model="org.legal_name"
                          color="info"
                          append-icon="mdi-domain"
                          :rules="nameRules"
                          label="Legal Name"
                          outlined
                          placeholder="Organisation Name *"
                          class="full-width my-1"
                        ></v-text-field>
                      </v-form>

                      <v-btn text @click="e1 = 1"> Back </v-btn>

                      <v-btn
                        v-if="step_two_disabled"
                        color="info"
                        @click="e1 = 3"
                        class="float-right"
                      >
                        Continue
                      </v-btn>
                      <v-btn
                        v-else
                        :loading="loading"
                        color="info"
                        @click="handleRegisterOrg2"
                        class="float-right"
                      >
                        Continue
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-form
                        ref="form_facility"
                        v-model="valid"
                        lazy-validation
                        class="my-5"
                      >
                        <v-text-field
                          dense
                          v-model="facility.facility"
                          color="info"
                          append-icon="mdi-domain"
                          :rules="nameRules"
                          label="Facility Name"
                          outlined
                          placeholder="Facility name *"
                          class="full-width my-1"
                        ></v-text-field>

                        <gmap-map 
                          ref="map"
                          :zoom="zoom"
                          :center="center"
                          style="max-width:100%; height:400px;"
                          @click="handleMapClick"
                        >
                          <GmapMarker
                            :position="centerPosition"
                            :clickable="true"
                            :draggable="true"
                            @drag="handleMarkerDrag"
                            @click="panToMarker"
                          />
                        </gmap-map>

                      </v-form>

                      <v-btn text @click="e1 = 2"> Back </v-btn>
                      <v-btn
                        :loading="loading"
                        color="info"
                        @click="handleRegisterFacility"
                        class="float-right"
                      >
                        Submit
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>

                  <div class="d-flex justify-center my-2">
                    <span class="text-caption grey--text">{{ this.currentYear }} &copy; Bluezone.</span>
                  </div>
                </v-stepper>
                
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="0">
                <!-- For placement -->
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import AuthService from '../../services/auth.service';

export default {
    data: () => ({
        showPassword: false,
        resendAfterMinute: true,
        zoom: 6,
        map: null,
        center: {lat: -1.262223346227177, lng: 36.80714185645724},
        centerPosition: {lat: -1.262223346227177, lng: 36.80714185645724},
        registration_otp: null,
        registration_response: [],
        verify_otp: false,
        facilityParentOrg: [],
        loading: false,
        // MAP
        e1: 1,
        selectedPlan: {},
        plan_selected: false,
        planData: {
            // Basic
            basicPrice: "14,900",
            basicUsers: "Upto 5 users",
            basicObjects: "Max 200 objects/month",
            basicQueries: "Unlimited queries",
            basicAnalytics: "Unlimited analytics",
            // Facility
            facilityPrice: "30,000",
            facilityUsers: "Upto 15 users",
            facilityObjects: "Max 400 objects/month",
            facilityQueries: "Unlimited queries",
            facilityAnalytics: "Unlimited analytics",
            // Organisation
            orgPrice: "60,000",
            orgUsers: "Upto 50 users",
            orgObjects: "Unlimited objects/month",
            orgQueries: "Unlimited queries",
            orgAnalytics: "Unlimited analytics",
        },
        modal_title: "Get started",
        sub_heading: "PRICING PLAN",
        user: {},
        editedItem: {
            phone: "",
            national_number: "KE",
        },
        org: {},
        facility: {},
        select_plan: true,
        toggle_exclusive: 0,
        step_one_disabled: false,
        step_two_disabled: false,
        show_basic_registration: false,
        show_organisation_registration: false,
        dialog: false,
        valid: true,
        remember: 0,
        timeout: 2000,
        otpRules: [
            (v) => !!v || "OTP is required",
            (v) => (v && v.length === 5) || "The OTP has 6 characters"
        ],
        passwordRules: [
            (v) => !!v || "Password is required",
            (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
        fullnameRules: [
            (v) => !!v || "Name is required",
            // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
        nameRules: [
            (v) => !!v || "Name is required",
            // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
        phoneRules: [
            (v) => !!v || "Phone is required",
            // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
        emailRules: [
            // (v) => !!v || "E-mail is required",
            // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            // (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
        currentYear: new Date().getFullYear(),
        config: {},
        show_facility_registration: false,

        apiErrors: [],
        phone_has_error: false,

    }),
    name: "register",
    components: {},
    opts: {
        theme: {
            dark: false,
        },
    },

    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        computedLogo() {
            return this.config.logo_url;
        },
        confirmPasswordRules() {
            return [
                v => !!v || 'Confirm Password is required',
                v => v === this.user.password || 'Passwords must match',
            ]
        },
        confirmOrgPasswordRules() {
          return [
              v => !!v || 'Confirm Password is required',
              v => v === this.org.password || 'Confirm Password is required'
          ];
        }
    },
    watch: {
        resendAfterMinute(newResendAfterMinute, oldResendAfterMinute) {
            if (newResendAfterMinute || oldResendAfterMinute) {
                this.resendOTPAfterMinute();
            }
        }
    },
    created() {
        this.$nextTick(() => {
            // this.resendOTPAfterMinute();
            this.geolocate();
        });
    },
    methods: {
        resendOTPAfterMinute() {
            setTimeout(() => {
                this.resendAfterMinute = false
            }, 5000)

        },
        setMonthly() {
            this.toggle_exclusive = 0;
            this.planData = {
                // Basic
                basicPrice: "0",
                basicUsers: "Upto 5 users",
                basicObjects: "Max 200 objects/month",
                basicQueries: "Unlimited queries",
                basicAnalytics: "Unlimited analytics",
                // Facility
                facilityPrice: "30,000",
                facilityUsers: "Upto 15 users",
                facilityObjects: "Max 400 objects/month",
                facilityQueries: "Unlimited queries",
                facilityAnalytics: "Unlimited analytics",
                // Organisation
                orgPrice: "60,000",
                orgUsers: "Upto 50 users",
                orgObjects: "Unlimited objects/month",
                orgQueries: "Unlimited queries",
                orgAnalytics: "Unlimited analytics",
            };
        },
        setQuarterly() {
            this.toggle_exclusive = 1;
            this.planData.facilityPrice = "90,000";
            this.planData.orgPrice = "180,00";
        },
        setBiannually() {
            this.toggle_exclusive = 2;
            this.toggle_exclusive = 1;
            this.planData.facilityPrice = "180,000";
            this.planData.orgPrice = "360,000";
        },
        setAnnually() {
            this.toggle_exclusive = 3;
            this.planData.facilityPrice = "360,000";
            this.planData.orgPrice = "720,000";
        },
        validate() {
            this.$refs.form.validate();
        },
        updatePhoneNumber(data) {
            this.formatted = data.e164;
        },
        backToPlan() {
            this.e1 = 1;
            this.select_plan = true;
            this.plan_selected = false;
            this.modal_title = "Get started";
            this.sub_heading = "SELECT PLAN";
            this.show_basic_registration = false;
            this.show_facility_registration = false;
            this.show_organisation_registration = false;
        },
        exitModal() {
            this.e1 = 1;
            this.select_plan = true;
            this.plan_selected = false;
            this.modal_title = "Get started";
            this.sub_heading = "SELECT PLAN";
            this.show_basic_registration = false;
            this.show_facility_registration = false;
            this.show_organisation_registration = false;
            this.dialog = false;
        },
        gotToRegisterBasic() {
            this.select_plan = false;
            this.plan_selected = true;
            this.modal_title = "Registration";
            this.sub_heading = "BASIC";
            this.show_basic_registration = true;
            this.show_organisation_registration = false;
        },
        gotToRegisterFacilityManager() {
            this.select_plan = false;
            this.plan_selected = true;
            this.modal_title = "Register facility manager";
            this.sub_heading = "FACILITY";
            this.show_facility_registration = true;
            this.show_organisation_registration = false;
        },
        gotToRegisterOrganisation() {
            this.select_plan = false;
            this.plan_selected = true;
            this.modal_title = "Register organisation";
            this.sub_heading = "ORGANISATION";
            this.show_facility_registration = false;
            this.show_organisation_registration = true;
        },
        gotToRegisterQuarterly() {
            this.$router.push("/quarterly");
        },
        gotToRegisterBiannually() {
            this.$router.push("/biannually");
        },
        gotToRegisterAnnually() {
            this.$router.push("/annually");
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        gotToForgotPassword() {
            this.$router.push("/forgot-password");
        },
        systemConfig() {
            setTimeout(() => {
                console.log("Loading settings..");
                this.loading_dialog = false;
                this.pageReady = true;
            }, 1000);
            return;
        },

        // samdoh
        handleRegister() {

            console.clear();
            console.group("Handle Register");
            this.$refs.form.validate();
            this.loading = true;
            this.user.phone = this.formatted;
            this.user.fullname = this.user.firstname + " " + this.user.lastname
            this.$store.dispatch("auth/register", this.user).then(
                (response) => {
                    if (response.status == 200) {

                        this.$store.dispatch("alert/success", response.message);
                        this.loading = false;
                        this.verify_otp = true;
                        this.resendOTPAfterMinute();

                        // Temporarily hold registration response
                        this.registration_response = response

                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        setTimeout(() => {
                            this.loading = false;
                            this.$router.push("/login");
                        }, 1500);
                        this.loading = false;
                    }
                },
                (error) => {
                    console.log(error.response)
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.apiErrors = error.response.data.errors
                            if (this.apiErrors.phone) {
                                this.phone_has_error = true
                            }
                            let errorArrays = Object.values(error.response.data.errors);
                            console.log(errorArrays);
                            let errorString = "";
                            errorArrays.forEach((element, i) => {
                                errorString += "(" + i + 1 + ")" + element + ", ";
                            });
                            this.$store.dispatch("alert/error", errorString);
                        }
                    } else {

                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },
        handleRegister2() {
            console.group("Handle register 2");
            console.group(this.step_one_disabled);
            this.$refs.form.validate();
            this.org.phone = this.formatted;
            this.org.fullname = this.org.firstname + " " + this.org.lastname
            this.loading = true;
            console.log(this.org)
            this.$store.dispatch("auth/register", this.org).then(
                (response) => {
                    if (response.status == 200) {

                        this.$store.dispatch("alert/success", response.message);
                        this.loading = false;

                        this.verify_otp = true;
                        this.resendOTPAfterMinute();

                        // Temporarily hold registration response
                        this.registration_response = response


                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        this.loading = false;
                    }
                },
                (error) => {
                    console.log(error)
                    if (error) {
                        if (error.response.status == 422) {
                            this.apiErrors = error.response.data.errors
                            if (this.apiErrors.phone) {
                                this.phone_has_error = true
                            }
                            this.loading = false;
                            let errorArrays = Object.values(error.response.data.error.detail);
                            let errorString = "";
                            errorArrays.forEach((element, i) => {
                                errorString += "(" + i + 1 + ")" + element + ", ";
                            });
                            this.$store.dispatch("alert/error", errorString);
                        }
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },
        handleRegisterOrg() {
            console.group("Register Org");
            this.$refs.form_org.validate();
            this.loading = true;
            this.org.isFacilityManager = 1;
            this.org.usr_id = localStorage.getItem("user_id");
            // this.org.id_parent = 0;
            this.org.phone = this.formatted;
            this.$store.dispatch("auth/registerOrg", this.org).then(
                (response) => {
                    console.group('Registration success')
                    console.log(response)
                    if (response.status == 200) {
                        localStorage.setItem(
                            "org",
                            JSON.stringify(response.data.organization)
                        );
                        localStorage.setItem("org_id", JSON.stringify(response.data.id));
                        this.$store.dispatch(
                            "alert/success",
                            "Organisation registered successfuly"
                        );
                        localStorage.setItem(
                            "token",
                            response.data.user.access_token
                        );
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data.user)
                        );
                        this.loading = false;
                        this.$router.push("/dashboard");
                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    }
                },
                (error) => {
                    if (error.response.status == 422) {
                        let errorArrays = Object.values(error.response.data.errors);
                        let errorString = "";
                        errorArrays.forEach((element, i) => {
                            errorString += "(" + i + 1 + ")" + element + ", ";
                        });
                        this.$store.dispatch("alert/error", errorString);
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },
        handleRegisterOrg2() {
            this.$refs.form_org.validate();
            this.loading = true;
            this.org.usr_id = localStorage.getItem("user_id");
            // this.org.id_parent = 0;
            console.log(this.org)
            this.$store.dispatch("auth/registerOrg", this.org).then(
                (response) => {
                    console.log(response.data)
                    if (response.status == 200) {
                        this.facilityParentOrg = response.data
                        this.$store.dispatch(
                            "alert/success",
                            "Organisation Registered Successfuly"
                        );
                        this.loading = false;
                        localStorage.setItem(
                            "org",
                            JSON.stringify(response.data.organization)
                        );

                        let token = response.data.user.access_token;
                        localStorage.setItem("token", token);

                        localStorage.setItem("org_id", JSON.stringify(response.data.id));
                        this.e1 = 3;
                        this.step_two_disabled = true;
                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    }
                },
                (error) => {
                    if (error.response.data.code == 422) {
                        let errorArrays = Object.values(error.response.data.error.detail);
                        let errorString = "";
                        errorArrays.forEach((element, i) => {
                            errorString += "(" + i + 1 + ")" + element + ", ";
                        });
                        this.$store.dispatch("alert/error", errorString);
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },
        handleRegister3() {
            this.$refs.form.validate();
            this.loading = true;
            this.user.phone = this.formatted;
            this.user.fullname = this.user.firstname + " " + this.user.lastname
            this.$store.dispatch("auth/register", this.user).then(
                (response) => {
                    if (response.status == 200) {
                        this.$store.dispatch("alert/success", response.message);
                        this.loading = false;

                        this.verify_otp = true;
                        this.resendOTPAfterMinute();

                        // Temporarily hold registration response
                        this.registration_response = response

                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        this.loading = false;
                    }
                },
                (error) => {
                    if (error.response.data) {
                        if (error.response.status == 422) {
                            this.apiErrors = error.response.data.errors
                            if (this.apiErrors.phone) {
                                this.phone_has_error = true
                            }
                            this.loading = false;
                            let errorArrays = Object.values(error.response.data.error.detail);
                            let errorString = "";
                            errorArrays.forEach((element, i) => {
                                errorString += "(" + i + 1 + ")" + element + ", ";
                            });
                            this.$store.dispatch("alert/error", errorString);
                        }
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },
        handleRegisterFacility() {
            this.$refs.form_facility.validate();
            // this.loading = true;
            // let org = localStorage.getItem("org");
            // let organization = JSON.parse(org);
            // this.facility.id_parent = 1;

            // this.facility.id_parent = 1;
            this.facility.usr_id = localStorage.getItem("user_id");
            this.facility.facility_manager = localStorage.getItem("user_id");
            this.facility.created_by = localStorage.getItem("user_id");
            this.facility.org_id = this.facilityParentOrg.organization.id
            this.facility.token = localStorage.getItem('token');

            console.log(this.facilityParentOrg)

            this.$store.dispatch("auth/registerFacility", this.facility).then(
                (response) => {

                    if (response.code == 200) {
                        localStorage.setItem(
                            "facilty_id",
                            JSON.stringify(response.data.id)
                        );
                        this.$store.dispatch(
                            "alert/success",
                            "Facility registered successfuly"
                        );
                        this.loading = false;
                        location.reload()
                        this.$router.push("/facility");
                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            response.data.message || response.message
                        );
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    }
                },
                (error) => {
                    console.log(error)
                    if (error.response.data.code == 422) {
                        let errorArrays = Object.values(error.response.data.error.detail);
                        let errorString = "";
                        errorArrays.forEach((element, i) => {
                            errorString += "(" + i + 1 + ")" + element + ", ";
                        });
                        this.$store.dispatch("alert/error", errorString);
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to register" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );
        },

        gotToLogin() {
            this.$router.push("/login");
        },
        onFinish(rsp) {
            this.verify_otp = true
            setTimeout(() => {
                this.verify_otp = false
                console.log(rsp)
                // this.snackbarColor = (rsp === 123456) ? 'success' : 'warning'
                // this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
                // this.snackbar = true
            }, 3500)
        },

        handleVerifyOTP(registrationPlan) {

            this.$refs.form.validate();
            this.user.otp = this.registration_otp;
            this.user.phone = this.formatted;

            AuthService.verifyOTP(this.user).then(
                (feedback) => {
                    if (feedback.status == 200) {

                        this.$store.dispatch("alert/success", "OTP verification successful");
                        this.loading = false;
                        this.verify_otp = false;

                        // Continue to login after otp verification
                        let response = this.registration_response;

                        localStorage.setItem(
                            "token",
                            response.data.access_token
                        );
                        localStorage.setItem("user_id", response.data.id);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data)
                        );

                        switch (registrationPlan) {

                            case "basic" :

                                this.$router.push("/dashboard");
                                break;

                            case "organization" :

                                this.e1 = 2;
                                this.step_one_disabled = true;
                                break;

                            case "facility" :

                                this.e1 = 2;
                                break;

                            default :

                        }


                    } else {
                        this.$store.dispatch(
                            "alert/info",
                            feedback.data.message || feedback.message
                        );
                        this.loading = false;
                    }
                },
                (error) => {
                    if (error.response.data) {
                        if (error.response.data.code == 422) {
                            let errorArrays = Object.values(error.response.data.error.detail);
                            let errorString = "";
                            errorArrays.forEach((element, i) => {
                                errorString += "(" + i + 1 + ")" + element + ", ";
                            });
                            this.$store.dispatch("alert/error", errorString);
                        } else if (error.response.data.code == 401) {

                            this.$store.dispatch("alert/error", error.response.data.message);

                        }
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            "Unable to verify OTP" ||
                            error.response.data.message ||
                            error.message
                        );
                    }
                    this.loading = false;
                }
            );


        },
        handleResendOTP() {

            this.resendAfterMinute = true;
            this.user.phone = this.user.phone ?? this.org.phone;
            AuthService.resendVerificationOTP(this.user).then(
                success => {
                    if (success.status == 200) {
                        this.$store.dispatch("alert/success", "OTP resend successfully");
                        this.resendOTPAfterMinute()
                    }
                },
                error => {
                    if (error) {
                        this.resendAfterMinute = false;
                        this.$store.dispatch("alert/error", "Unable to resend verification OTP");
                    }
                }
            );
        },

        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {

                this.centerPosition.lat = position.coords.latitude;
                this.centerPosition.lng = position.coords.longitude;
                this.center = this.centerPosition;
                this.zoom = 18;

                // this.panToMarker();

            });
        },

        //sets the position of marker when dragged
        handleMarkerDrag(e) {

            this.centerPosition = {lat: e.latLng.lat(), lng: e.latLng.lng()};
            this.panToMarker();

        },

        //Moves the map view port to marker
        panToMarker() {

            this.facility.latitude = this.centerPosition.lat ?? this.center.lat
            this.facility.longitude = this.centerPosition.lng ?? this.center.log

            this.$refs.map.panTo(this.centerPosition);
            this.zoom = 18;

        },

        //Moves the marker to click position on the map
        handleMapClick(e) {

            console.log(e)
            this.centerPosition = {lat: e.latLng.lat(), lng: e.latLng.lng()};
            this.panToMarker();

        },
    },
};
</script>
<style scoped>
 .position-relative {
   position: relative;
 }
</style>
