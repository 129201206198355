// Data sevice
import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL;

class ConfigService {
    index() {
        axios.get(API_URL + 'close-all-past-tenders')
        return axios.get(API_URL + 'system-config')
    }
}

export default new ConfigService()
