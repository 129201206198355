<template>
  <v-app>
    <router-view> </router-view>
    <SnackBar />
    <Alert />
  </v-app>
</template>
<style>
th {
  font-size: 14px !important;
  text-transform: uppercase;
}
</style>
<script>
import SnackBar from "@/components/SnackBar";
import Alert from "@/components/Alert";

export default {
  name: "App",
  components: {
    SnackBar,
    Alert,
  },
  data: () => ({
    page: "Dashboard",
    timeout: 2000,
    dark_mode: false,
  }),
  computed: {},
  methods: {},
};
</script>
