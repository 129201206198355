// Data sevice
import Axios from './Axios'
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class UserService {

  index() {
    return Axios({
      method: "GET", url: 'users'
    });
  }

  orgIndex(id) {
    return axios.get(API_URL + 'user' + id, { headers: authHeader() })
  }

  store(item) {
    return Axios({
      method: "POST", url: 'users', data: item
    });
  }

  async sendOtp(item) {
    return Axios({
      method: "POST", url: 'user/reset/password/otp', data: item
    });
  }

  async resetPasswordWithOTP(item) {
    return Axios({
      method: "POST", url: 'user/reset/password', data: item
    });
  }

  updateOrganizationUser(item) {
    return Axios({
      method: "PATCH", url: 'organization-user/' + item.id, data: item
    });
  }

  assignOrganizationUserRoles(item) {
    return Axios({
      method: "POST", url: 'organization-user-roles/' + item.id, data: item
    });
  }

  update(item) {
    return Axios({
      method: 'POST', url: 'user', ///'+item.id,
      data: item
    });
  }

  delete(item) {
    return axios.delete(API_URL + 'user' + item.id, { headers: authHeader() });
  }

  /**
   * Users
   */
  getAllUsers() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }

  getAllCompanyUsers() {
    let user = JSON.parse(localStorage.getItem("user"));
    return axios.get(API_URL + 'users/company/' + user.company_id, { headers: authHeader() });
  }

  storeUser(user) {
    return axios.post(API_URL + 'users', user, { headers: authHeader() });
  }

  updateUser(user) {
    return axios.put(API_URL + 'users/' + user.id, user, { headers: authHeader() });
  }

  getUser(user) {
    return axios.get(API_URL + 'users/' + user.id, { headers: authHeader() });
  }

  deleteUser(user) {
    return axios.delete(API_URL + 'users/' + user.id, { headers: authHeader() });
  }

  /**
   * Roles
   */
  getAllRoles() {
    return axios.get(API_URL + 'roles', { headers: authHeader() })
  }

  storeRole(role) {
    return axios.post(API_URL + 'roles', role, { headers: authHeader() })
  }

  updateRole(role) {
    return axios.put(API_URL + 'roles/' + role.id, role, { headers: authHeader() })
  }

  deleteRole(role) {
    return axios.delete(API_URL + 'roles/' + role.id, { headers: authHeader() })
  }
}

export default new UserService()
