<template>
  <v-container fill-height fluid class="lighten-5 text-center ma-0 pa-0">
    <v-layout align-center justify-center>
      <!-- Loader  -->
      <v-row align="center" justify="center" v-if="loading_dialog">
        <v-col>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              :width="2"
              :size="25"
            ></v-progress-circular>
          </div>
          <p caption class="mt-2">Loading... Please wait</p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col start>
          <!-- only touch if you know what you're doing -->
        </v-col>
        <v-col cols="12" xs="8" sm="6" md="4">
          <v-card class="mx-auto spacing-playground pa-2 align-center" max-width="400" outlined>
            <div class="d-flex justify-center mt-5">
              <v-img lazy-src="img/logo-128.png" max-height="178" max-width="178" src="img/logo-128.png"></v-img>
            </div>
            <div class="mt-4">
              <h4 class="grey--text text--darken-2 d-flex justify-center no-uppercase">
                Sign in
              </h4>
            </div>

            <!-- Right band  -->
            <v-divider class="mt-2"></v-divider>
            <div>
                <v-card-text>
                   <v-form ref="form" v-model="valid" lazy-validation class="mt-5" v-if="otp_verified">
                       <v-text-field dense v-model="user.username" color="info" append-icon="mdi-account-outline"
                                     :rules="nameRules" label="Your Email or phone (+2547...) *"
                                     outlined class="full-width my-1">
                       </v-text-field>

                  <v-text-field dense outlined v-model="user.password" color="info" :rules="passwordRules"
                    append-icon="mdi-eye-outline" :type="showPassword ? 'text' : 'password'" label="Password"
                    class="full-width my-1" hint="At least 8 characters" :autofocus="true"
                                @click:append="showPassword = !showPassword">
                  </v-text-field>

                  <v-btn
                    id="login_btn"
                    :loading="loading"
                    color="info"
                    elevation="0"
                    medium
                    block
                    class="ml-auto white--text my-1 no-uppercase"
                    @click="handleLogin"
                    >Sign In</v-btn
                  >
                </v-form>

                   <v-form v-if="!otp_verified && !resend_otp"
                           ref="form"
                           v-model="valid"
                           lazy-validation
                           class="mt-5"
                   >

                       <div class="pb-5">
                           <template>
                               <vue-phone-number-input
                                       v-model="phone"
                                       required
                                       @update="updatePhoneNumber"
                                       :default-country-code="national_number"
                                       size="lg"
                               ></vue-phone-number-input>
                    </template>
                  </div>

                  <v-text-field
                    dense
                    v-model="verification_otp"
                    color="info"
                    append-icon="mdi-numeric"
                    :rules="otpRules"
                    label="Verification OTP"
                    outlined
                    placeholder="123456"
                    class="full-width my-1 centered-input"
                  ></v-text-field>

                  <v-row class="pb-5">
                    <v-col>
                      <v-btn block class="no-uppercase info"
                        @click="handleVerifyOTP"
                      >Verify OTP</v-btn>
                    </v-col>
                  </v-row>

                </v-form>

                <v-form v-if="resend_otp && !otp_verified"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="mt-5"
                >
                  <template>
                    <vue-phone-number-input
                      v-model="phone"
                      required
                      @update="updatePhoneNumber"
                      :default-country-code="national_number"
                      size="lg"
                    ></vue-phone-number-input>
                  </template>

                    <v-row class="py-5">
                        <v-col>
                            <v-btn block class="no-uppercase info"
                                   @click="handleResendOTP"
                            >Send OTP
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>
               </v-card-text>
                <v-card-actions class="mx-2" v-if="otp_verified">
                    <a class="text-caption text-sm text-decoration-none" @click="gotToForgotPassword">
                        <span text-caption> Forgot password ?</span>
                    </a>
                    <span class="ml-auto mr-4">
                  <Register/>
                </span>
                </v-card-actions>
                <v-card-actions class="mx-2" v-if="!otp_verified && !resend_otp">
                    <a class="text-caption text-sm text-decoration-none" @click="resend_otp = true">
                        <span text-caption> Resend OTP</span>
                    </a>
                    <span class="ml-auto mr-4 text-caption" @click="otp_verified = true">
                    <a>Login</a>
                  </span>
                </v-card-actions>
                <v-card-actions class="mx-2" v-if="!otp_verified && resend_otp">
                    <a class="text-caption text-sm text-decoration-none" @click="resend_otp = false">
                        <span text-caption>Back</span>
                    </a>
                </v-card-actions>
            </div>
              <v-divider class="mt-2"></v-divider>

              <div class="d-flex justify-center mt-5">
                <span class="text-caption grey--text">
                    {{ this.currentYear }} &copy; Bluezone.
                </span>
              </div>
          </v-card>
        </v-col>
        <v-col end>
          <!-- only touch if you know what you're doing -->
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Register from "./Register.vue";
import AuthService from '../../services/auth.service';
import Axios from '../../services/Axios';

export default {
  data: () => ({
    showPassword : false,
    resend_otp : false,
    national_number : "KE",
    otp_verified : true,
    verification_otp : null,
    loading: false,
    toggle_exclusive: undefined,
    loading_dialog: true,
    pageReady: false,
    user: {
      username: " ",
    },
    phone: null,
    valid: true,
    remember: 0,
    timeout: 2000,
    otpRules : [
      (v) => !!v || "OTP is required",
      (v) => (v && v.length == 5) || "The OTP has 6 characters"
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Email or phone (+2547...) is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "Login",
  components: { Register },
  opts: {
    theme: {
      dark: false,
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },
  created() {
    localStorage.clear();
    this.systemConfig();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    gotToRegisterBasic() {
      this.$router.push("/register");
    },
    gotToRegisterFacilityManager() {
      this.$router.push("/register-facility-manager");
    },
    gotToRegisterOrganisation() {
      this.$router.push("/register-organisation");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gotToForgotPassword() {
        this.$router.push("/forgot-password");
    },
    systemConfig() {
      setTimeout(() => {
        console.log("Loading settings..");
        this.loading_dialog = false;
        this.pageReady = true;
      }, 1500);
      return;
    },
    handleLogin() {
      this.$refs.form.validate();
      this.$store.dispatch("setLoading", true);
      this.$store.dispatch("auth/login", this.user).then(
        (response) => {
          if (response.status == 200) {
            Axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.data.access_token;
            localStorage.setItem("token", response.data.data.access_token);
            
            if (response.data.data.organization) {
              localStorage.setItem("org", JSON.stringify(response.data.data.organization));
            }

            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("userData", JSON.stringify(response.data.data));

            this.$store.dispatch("alert/success", response.data.message);
              setTimeout(() => {
              this.$store.dispatch("setLoading", false);
              this.$router.push("/dashboard")
            }, 1500);
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          let status = error.response.status;
          if (status == 401 && error.response.data.error.type == 'OTP') {
            this.otp_verified = false;
            let contact = this.user.username.slice(-9);

            if (!isNaN(contact)) {
              this.phone = contact;
            }
          }
          
          this.$store.dispatch("setLoading", false);
          this.$store.dispatch(
            "alert/error",
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Wrong credentials"
          );
        }
      );
    },

    updatePhoneNumber(data) {
      this.formatted = data.e164;
    },

    handleVerifyOTP() {

      this.$refs.form.validate();
      this.user.otp = this.verification_otp;
      this.user.phone = this.formatted;

      AuthService.verifyOTP(this.user).then(
        (feedback) => {
          if (feedback.status == 200) {

            this.$store.dispatch("alert/success", feedback.data.message);
            this.loading = false;
            this.verify_otp = false;

            this.otp_verified = true;


          } else {
            this.$store.dispatch(
              "alert/info",
              feedback.data.message || feedback.message
            );
            this.loading = false;
          }
        },
          (error) => {
              if (error.response.data) {
                  if (error.response.data.code == 422) {
                      let errorArrays = Object.values(error.response.data.error.detail);
                      let errorString = "";
                      errorArrays.forEach((element, i) => {
                          errorString += "(" + i + 1 + ")" + element + ", ";
                      });
                      this.$store.dispatch("alert/error", errorString);
                  } else if (error.response.data.code == 401) {

              this.$store.dispatch("alert/error", error.response.data.message);

            }
          } else {
            this.$store.dispatch(
              "alert/error",
              "Unable to verify OTP" ||
                error.response.data.message ||
                error.message
            );
          }
          this.loading = false;
        }
      );


    },

    handleResendOTP() {

      this.$refs.form.validate();
      this.user.phone = this.formatted;

      AuthService.resendVerificationOTP(this.user).then(
        (feedback) => {
          if (feedback.status == 200) {

            this.$store.dispatch("alert/success", feedback.data.message);
            this.loading = false;
            this.verify_otp = false;

            this.resend_otp = false;
            this.otp_verified = false;

          } else {
            this.$store.dispatch(
              "alert/info",
              feedback.data.message || feedback.message
            );

            this.loading = false;

          }
        },
          (error) => {
              if (error.response.data) {

                  if (error.response.data.code == 422) {
                      let errorArrays = Object.values(error.response.data.error.detail);
                      let errorString = "";
                      errorArrays.forEach((element, i) => {
                          errorString += "(" + i + 1 + ")" + element + ", ";
                      });
                      this.$store.dispatch("alert/error", errorString);
                  } else if (error.response.data.code == 401) {

              this.$store.dispatch("alert/error", error.response.data.message);

            }

          } else {
            this.$store.dispatch(
              "alert/error",
              "Unable to verify OTP" ||
                error.response.data.message ||
                error.message
            );
          }
          this.loading = false;
        }
      );

    },


  },
};
</script>
