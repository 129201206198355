import axios from 'axios'

let access_token = localStorage.getItem('token');
const Axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // timeout : 1000,
    headers: {
        common: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    }
});

export default Axios;

