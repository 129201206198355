import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueGoogleCharts from 'vue-google-charts';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import OtpInput from "@bachdgvn/vue-otp-input";

// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');

// // This assumes you have already saved your bearer token in your browsers local storage
// const token = localStorage.getItem('token');

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_PUSHER_APP_KEY,
//   wsHost: window.location.hostname,
//   authEndpoint: process.env.VUE_APP_PUBLIC_URL + '/broadcasting/auth',
//   cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
//   encrypted: false,
//   forceTLS: false,
//   wsPort: 6001,
//   // wssPort: 6001,
//   disableStats: true,
//   // enabledTransports: ['ws', 'wss'],
//   enabledTransports: ['ws'],
//   auth: {
//     headers: {
//       authorization: 'Bearer ' + token,
//     }
//   }
// });

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.component('vue-otp-input', OtpInput);

Vue.config.productionTip = false

Vue.use(VueGoogleCharts);

// use this package's plugin
Vue.use(CKEditor);

Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VueGoogleMaps, {   
  load: {     
    key: "AIzaSyBTHaSdsfU3cqxQgwzl8LSGlR41vwD8NZ4",     
    libraries: "places"   
  } 
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
