<template>
    <div>
        <h1>Sorry Page doesnt exist</h1>
        <h3>The {{resource}} you are looking for is not here</h3>
        <router-link :to="{name: 'DashBoard'}">Back to the home page</router-link>

    </div>
</template>

<script>
    export default {
        props: {
            resource: {
                type: String,
                required: true,
                default: 'page'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>