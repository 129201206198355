<template>
    <v-container fill-height fluid class="lighten-5 text-center" style="position: relative">

        <!-- Loader  -->
        <v-row align="center" justify="center" v-if="dialog">
            <v-col>
                <v-progress-circular indeterminate color="info" class="my-aut"></v-progress-circular>
                <p caption>Loading... Please wait</p>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="pageReady">
            <v-col cols="12" sm="12" class="mx-auto">
                <!-- Default Reset by OTP -->
                <v-card class="mx-auto mt-16 pt-5" max-width="412" outlined>
                    <v-row no-gutters>
                        <!-- Right band  -->
                        <v-col cols="12" order="last" border="none" class="my-n">
                            <div>
                                <div class="d-flex justify-center">
                                    <v-img lazy-src="img/logo-128.png" max-height="178"
                                           max-width="178" src="img/logo-128.png">
                                    </v-img>
                                </div>
                                <div class="mt-3">
                                  <span class="d-flex justify-center mx-5 blue--text text--darken-2">
                                    <caption>
                                      Input OTP code sent to your phone number
                                    </caption>
                                  </span>
                                </div>
                                <v-divider class="mt-1 mb-1"></v-divider>
                                <v-card-text>
                                    <v-form ref="form" v-model="valid" lazy-validation class="mt-5">

                                        <v-text-field
                                            v-model="user.otp"
                                            :maxlength="5"
                                            :loading="isLoading"
                                            label="Enter OTP"
                                            :rules="[
                                                (value) => !!value || 'OTP is required',
                                                (value) => (value && value.length === 5) || 'OTP should be 5 characters',
                                            ]"
                                            @keydown="onKeyDown"
                                            @input="onInput"
                                        >
                                            <template #append>
                                                <v-progress-circular
                                                    v-if="isLoading"
                                                    indeterminate
                                                    size="22"
                                                    color="blue"
                                                ></v-progress-circular>
                                            </template>
                                        </v-text-field>
                                        <v-text-field v-model="user.password" :rules="passwordRules"
                                            label="Password" type="password" required
                                        ></v-text-field>
                                        <v-text-field v-model="user.confirm_password" :rules="confirmPasswordRules"
                                            label="Confirm Password" type="password" required
                                        ></v-text-field>
                                    </v-form>
                                </v-card-text>

                                <v-divider class="mt-n4"></v-divider>
                                <v-card-actions class="mt-3">
                                    <v-btn color="white" medium class="ml-3 blue--text" @click="gotToLogin" outlined>
                                        Back to Login
                                    </v-btn>
                                    <v-btn :loading="loading" color="blue" elevation="2" medium width="80"
                                           class="ml-auto white--text"  :disabled="isEnabled"
                                           @click="handleResetPass">
                                        Reset
                                    </v-btn>
                                </v-card-actions>

                            </div>
                        </v-col>
                    </v-row>
                </v-card>
                <div class="d-flex justify-center mt-5">
                    <span class="text-caption blue--text">{{ this.currentYear }} &copy; The blueion LTD.</span>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import User from "../../models/user";
import index from "vuex";
import ConfigService from "@/services/config.service";
import UserService from "@/services/user.service";

export default {
    name: "ResetPassWithOTP",
    props: {
        digits: {
            type: Number,
            default: 5,
        },
    },
    data: () => ({
        isLoading: false,
        dialog: true,
        isEnabled: false,
        pageReady: false,
        user: new User("", ""),
        valid: true,
        remember: 0,
        timeout: 2000,
        currentYear: new Date().getFullYear(),
        config: {},
        otp: "",
        otpLength: 5,
        otpRules: [
            (v) => !!v || "OTP is required",
            (v) => (v && v.length === this.otpLength) || `OTP must be ${this.otpLength} characters`,
            (v) => /^\d+$/.test(v) || "OTP must be numeric"
        ],

    }),
    computed: {
        index() {
            return index
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        loading() {
            return this.$store.state.loading;
        },
        computedLogo() {
            return this.config.logo_url;
        },
        passwordRules() {
            return [
                v => !!v || 'Password is required',
                v => v && v.length >= 6 || 'Password must be at least 6 characters',
                v => /^(?=.*[a-z])/.test(v) || 'Password must contain at least one lowercase letter',
                v => /^(?=.*[A-Z])/.test(v) || 'Password must contain at least one uppercase letter',
                v => /^(?=.*\d)/.test(v) || 'Password must contain at least one number',
                v => /^(?=.*[-!@#$%^&*()_+|~=`{}[:;<>?,.@#\]]).*$/.test(v) || 'Password must contain at least one special character',
            ];
        },

        confirmPasswordRules() {
            return [
                v => !!v || 'Confirm Password is required',
                v => v === this.user.password || 'Passwords must match',
            ]
        },
    },
    methods: {
        handleResetPass() {
            console.log(this.$route.params.phone);
            if(!this.user.otp || !this.user.password || !this.user.confirm_password) {
                this.$store.dispatch("alert/error", "Please key in all inputs");
            } else {
                const data = JSON.stringify({
                    "otp": this.user.otp,
                    "phone": this.$route.params.phone,
                    "password": this.user.password,
                    "password_confirmation": this.user.confirm_password
                });
                UserService.resetPasswordWithOTP(data).then(response => {
                        if (response.status === 200) {
                            this.$store.dispatch("setLoading", false);
                            this.$store.dispatch(
                                "alert/success",
                                "Password reset successful, please login!"
                            );
                            this.$router.push("/login");
                        } else {
                            this.$store.dispatch("alert/error", response.data.message);
                            this.$store.dispatch("setLoading", false);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.$store.dispatch("alert/error", error.response.data.message);
                        this.$store.dispatch("setLoading", false);
                    }
                );
            }

        },
        onKeyDown(event) {
            // Restrict input to digits only
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },
        onInput(event) {
            // Update model only if input value is a digit
            const input = event.target.value;
            if (input.length <= 5 && /^\d*$/.test(input)) {
                this.user.otp = input;
                this.isEnabled = true;
            }
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        gotToLogin() {
            this.$router.push("/login");
        },
        systemConfig() {
            return ConfigService.index().then(
                (response) => {
                    setTimeout(() => {
                        if (response.status === 200) {
                            this.config = response.data.data;
                            this.dialog = false;
                            this.pageReady = true;
                        } else {
                            console.log(response.data.error);
                        }
                    }, 1000);
                },
                (error) => {
                    console.log(error);
                }
            );
        },
    },
    created() {
        localStorage.clear();
        this.systemConfig();
    },
    mounted() {
        console.log("Phone: " + this.$route.params.phone);
    }
}
</script>

<style scoped>
</style>