import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from './auth.module'
import {alert} from './alert.module'
import {user} from './user.module'
import {reports} from './reports.module'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)
// Create store
export default new Vuex.Store({
    plugins: [createPersistedState()],

    state: {
        mini: false,
        loading: false,
        drawer: true
    },

    mutations: {
        minifyDrawer(state, val) {
            state.mini = val
        },
        showDrawer(state, val) {
            state.drawer = val
        },
        setLoading(state, val) {
            state.loading = val
        },
    },

    actions: {
        minifyDrawer({commit}, val) {
            commit('minifyDrawer', val)
        },
        showDrawer({commit}, val) {
            commit('showDrawer', val)
        },
        setLoading({commit}, val) {
            commit('setLoading', val)
        }
    },

    modules: {auth, alert, user, reports,}
})
