import Axios from './Axios';

class ReportsService {

    listAll(payload, watchmanId = null) {
        return Axios({
            method : "GET",
            url : "visitor_reports?search_term=" + payload + "&watchman_id=" + watchmanId, 
        })
    }

    upcomingVisits(organization_id) {
        return Axios({
            method : "GET",
            url : "organization_upcoming_visits/" + organization_id
        });
    }

    pastVisits(organization_id) {
        return Axios({
            method : "GET",
            url : "organization_past_visits/" + organization_id
        });
    }

    smsLogs(organisation_id) {
        return Axios({
            method: "GET",
            url: "sms-logs/" + organisation_id
        });
    }
}

export default new ReportsService()
