import ReportsService from "../services/reports.service"

const snackbar = {
    show: false,
    message: '',
    color: ''
}
const initialState = {
    reports: [],
    loading: false
}

export const reports = {
    namespaced: true,
    state: {
        ...initialState,
        snackbar
    },

    getters: {
        getAllReports: (state) => state.reports,
        setIsLoading: (state) => state.loading,
        findReport: (state) => (id) => {
            return state.reports.find(report => report.id === id)
        },
        getSnackBar: (state) => state.snackbar,
    },

    mutations: {
        REPORT_SUCCESS(state, response) {
            state.reports = response;
            state.loading = false;
        },

        MUTATE_SET_IS_LOADING(state, val) {
            state.loading = val;
        },

        REPORT_FAILURE(state, error) {
            state.snackbar.show = true;
            state.snackbar.color = 'error';
            state.snackbar.message = error.response.data.message ||
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
        },
    },

    actions: {
        async list({ commit }) {
            const payload = "";
            commit('MUTATE_SET_IS_LOADING', true);
            return await ReportsService.listAll(payload).then(
                response => {
                    commit('REPORT_SUCCESS', response.data.data);
                    commit('MUTATE_SET_IS_LOADING', false);
                },
                error => {
                    commit('REPORT_FAILURE', error);
                    commit('MUTATE_SET_IS_LOADING', false);
                }
            )
        },

        // eslint-disable-next-line no-unused-vars
        async filter({ commit }, payload) {
            commit('MUTATE_SET_IS_LOADING', true);
            return await ReportsService.listAll(payload.payload, payload.watchman_id).then(
                response => {
                    commit('REPORT_SUCCESS', response.data.data);
                    commit('MUTATE_SET_IS_LOADING', false);
                },
                error => {
                    console.log(error);
                    commit('REPORT_FAILURE', error);
                    commit('MUTATE_SET_IS_LOADING', false);
                }
            )
        }
    },
}