// Authentication service
import Axios from './Axios';

class AuthService {
    // login function
    login(user) {
        return Axios({
            method : "POST",
            url : 'login',
            data : user
        });
    }
        // logout function
    logout() {
        localStorage.removeItem('user')
    }
    async checkAuth() {
        return Axios({
            method : "GET",
            url : 'users',
        });
    }

    // register function
    register(user) {
        return Axios({
            method : 'POST',
            url : 'register', 
            data : user
        });
    }

    verifyOTP(user) {
        return Axios({
            method : "POST",
            url : "user/verify/otp",
            data : user
        });
    }

    resendVerificationOTP(user) {
        return Axios({
            method : "POST",
            url : "resend-verification-otp",
            data : user
        });
    }

    registerOrg(org) {
        return Axios({
            method : "POST",
            url : 'organization-register', 
            data : org, 
        })
    }

    registerFacility(facility) {
        if(facility.token !== undefined) 
        {
            return Axios({
                method : "POST",
                url : 'facility',
                data : facility,
                headers : {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'Authorization' : 'Bearer '+ facility.token
                }
            });
        }
        return Axios({
            method : "POST",
            url : 'facility',
            data : facility
        });
    }


}

export default new AuthService()