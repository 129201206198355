// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class CompanyService {

    getAll() {
        return axios.get(API_URL + 'company', { headers: authHeader() })
    }
    store(user) {
        return axios.post(API_URL + 'company', user, { headers: authHeader() })
    }
    update(user) {
        return axios.put(API_URL + 'company/'+user.id, user, { headers: authHeader() })
    }
    updateLogo(company) {
        return axios.post(API_URL + 'update-company-logo', company, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(API_URL + 'company/'+user.id, { headers: authHeader() })
    }
    updateSettings(settings) {
        return axios.put(API_URL + 'system-settings/'+settings.id, settings, { headers: authHeader() })
    }
}

export default new CompanyService()
