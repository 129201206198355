const initialState = {
    color: '',
    message: '',
    show: false
}
export const alert = {
    namespaced: true,
    state: initialState,
    actions: {
        success({ commit }, message) {
            commit('success', message)
        },
        error({ commit }, message) {
            commit('error', message)
        },
        info({ commit }, message) {
            commit('info', message)
        },
        clear({ commit }) {
            commit('clear')
        }
    },
    mutations: {
        success(state, message) {
            // state.color = 'info';
            state.color = 'success';
            state.message = message;
            state.show = true;
        },
        error(state, message) {
            state.color = 'error';
            state.message = message;
            state.show = true;
        },
        info(state, message) {
            state.color = 'info';
            state.message = message;
            state.show = true;
        },
        clear(state) {
            state.color = '';
            state.message = '';
            state.show = false;
        }
    },

}