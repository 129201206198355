<template>
  <v-container fill-height fluid class="lighten-5 text-center ma-0 pa-0">
    <!-- Loader  -->
    <v-row v-if="dialog" align="center" justify="center">
      <v-col>
        <v-progress-circular
          indeterminate
          color="info"
          class="my-3"
        ></v-progress-circular>
        <p caption>Loading... Please wait</p>
      </v-col>
    </v-row>

    <v-row v-else no-gutters class="fill-height">
      <v-col
        cols="9"
        xl="9"
        lg="9"
        md="9"
        sm="12"
        class="float-left fill-height"
      >
        <v-img
          lazy-src="img/meeting.jpeg"
          src="img/logo-128.jpeg"
          class="fill-height"
        ></v-img>
      </v-col>
      <v-col cols="3" xl="3" lg="3" md="3" sm="12" class="mt-n3">
        <v-card class="pa-3 mr-0 fill-height" tile>
          <!-- Default Login  -->
          <!-- Right band  -->
          <div class="pt-5">
            <div class="d-flex justify-center">
              <v-img
                lazy-src="img/logo-128.png"
                max-height="178"
                max-width="178"
                src="img/logo-128.png"
              ></v-img>
            </div>
            <div class="mt-4">
              <h4 class="grey--text text--darken-2 d-flex justify-center">
                Register
              </h4>
            </div>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
                <v-text-field
                  dense
                  v-model="user.name"
                  color="info"
                  append-icon="mdi-account-outline"
                  :rules="nameRules"
                  label="Your username *"
                  outlined
                  placeholder="Your username*"
                  class="full-width my-1"
                ></v-text-field>


                <v-text-field
                  dense
                  v-model="user.phone"
                  color="info"
                  append-icon="mdi-phone-outline"
                  :rules="phoneRules"
                  label="Phone *"
                  outlined
                  placeholder="Phone *"
                  class="full-width my-1"
                ></v-text-field>

                <v-text-field
                  dense
                  v-model="user.password"
                  color="info"
                  append-icon="mdi-eye-outline"
                  :rules="passwordRules"
                  :type="'Password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 8 characters"
                  outlined
                  placeholder="Password"
                  class="full-width my-1"
                ></v-text-field>

                <v-text-field
                  dense
                  color="info"
                  append-icon="mdi-eye-outline"
                  :rules="passwordRules"
                  :type="'Password'"
                  name="input-10-1"
                  label="Confirm_Password"
                  hint="At least 8 characters"
                  outlined
                  placeholder="Confirm_Password"
                  class="full-width my-1"
                ></v-text-field>

                <v-btn
                  :loading="loading"
                  color="info"
                  elevation="2"
                  medium
                  block
                  class="ml-auto white--text my-1"
                  @click="handleRegister"
                  >Register</v-btn
                >
              </v-form>
            </v-card-text>

            <v-card-actions class="mx-2">
              <a
                @click="gotToLogin"
                class="ml-auto text-caption text-decoration-underline"
                > Have an account?</a
              >
            </v-card-actions>
          </div>

          <div class="d-flex justify-center mt-5">
            <span class="text-caption grey--text"
              >{{ this.currentYear }} &copy; Bluezone.</span
            >
          </div>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../../models/user";

export default {
  data: () => ({
    dialog: true,
    pageReady: false,
    user: new User("", ""),
    valid: true,
    remember: 0,
    timeout: 2000,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "Login",
  opts: {
    theme: {
      dark: false,
    },
  },
  components: {},

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },
  created() {
    localStorage.clear();
    this.systemConfig();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
     gotToLogin() {
      this.$router.push("/login");
    },
    systemConfig() {
      setTimeout(() => {
        console.log("Loading settings..");
        this.dialog = false;
        this.pageReady = true;
      }, 1000);
      return;
    },
    handleRegister() {
      this.$refs.form.validate();
      if (this.user.email && this.user.password) {
        this.$store.dispatch("setLoading", true);
        this.$store.dispatch("auth/register", this.user).then(
          (response) => {
            if (response.status == 200) {
              if (response.data.user.is_admin == 1) {
                setTimeout(() => {
                  this.$store.dispatch("setLoading", false);
                  this.$router.push("/login");
                }, 1500);
              } else {
                setTimeout(() => {
                  this.$store.dispatch("setLoading", false);
                  if (response.data.user.company.profile_complete) {
                    this.$router.push("/marketplace");
                  } else {
                    this.$router.push("/complete-profile");
                  }
                }, 1500);
              }
            }
          },
          (error) => {
            this.$store.dispatch("setLoading", false);
            console.log(error);
          }
        );
      }
    },
  },
};
</script>
